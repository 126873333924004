
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Footer } from "./Footer";
import Navbar from './Navbar';
import Elipse from './assets/elipse.png'
import { ArrowRight } from "react-bootstrap-icons";
import { MDBContainer } from "mdb-react-ui-kit";

import "./styles/experiencias.css"

const Experience = () => {
  const videos = [
    {
      id: 1,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "Parque Global, Showroom Experience",
      client: "",
      agency: "",
      description: "Uma narrativa emocional e impactante que torna a experiência de uma simples visita em um espetáculo único.",
      paddingTop: "15vh",
      url: "https://player.vimeo.com/video/491949754?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 2,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "right",
      title: "Mozak, Parque Sustentável da Gávea",
      client: "",
      agency: "",
      description: "Uma instalação viva, onde a música e a imagem se unem para uma experiência sensorial completa e emocional.",
      paddingTop: "12vh",
      url: "https://player.vimeo.com/video/731196032?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 3,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "Odebrecht, Parque da Cidade",
      client: "",
      agency: "",
      description: "Uma mega cúpula 360° que conecta a tecnologia à realidade, em uma experiência inédita no Brasil.",
      paddingTop: "16vh",
      url: "https://player.vimeo.com/video/755787559?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 4,
      tags: [{ name: "", color: 'tag-blue' }/* , {name: "GLASS / CANNES", color: 'tag-yellow'} */],
      direction: "right",
      title: "Fernando & Sorocaba, Sou Interior",
      client: "",
      agency: "",
      description: "Em meio a um milharal, um palco de festival composto por máquinas agrícolas homenageia o povo do campo.",
      paddingTop: "12vh",
      url: "https://player.vimeo.com/video/380826913?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 5,
      tags: [{ name: "", color: 'tag-blue', award: "GUINNESS WORLD RECORDS" }/* , {name: "GLASS / CANNES", color: 'tag-yellow'} */],
      direction: "left",
      title: "Hyundai, Shadow Art",
      client: "",
      agency: "",
      description: "Criamos a maior Shadow Art da história. Com mais de 15 metros de altura e 8 metros de extensão.",
      paddingTop: "12vh",
      url: "https://player.vimeo.com/video/102657043?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    }
  ]
  videos.sectionHeightCustom = 'section-video-experiences'
  return (
    <>
      <Navbar />
      <Container fluid className='section section-experiences'>
        <Row>
          <Col className='title-page'>
            <h4>Experiências</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6} className='subtitle-experiences desk'>
            <p>
            A NSI tira grandes ideias do papel e as torna possíveis.
            </p>
          </Col>
          <Col lg={6} xl={6} className='subtitle-experiences-right'>
            <p>
              Somos especializados em criar e produzir  <span className='link-experiences'>experiências transformadoras e memoráveis</span>, conectando o público às marcas.
            </p>
            <p>
              Criamos poderosas ferramentas de venda, nela conseguimos sintetizar informações e materializar o conceito do produto de forma <span className='link-experiences'>impactante e sensorial</span>.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className='section second-section-experiences' >
        <Row>
          <Col>
            <img className='elipse elipse-1' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-2' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-3' src={Elipse} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2 }} className='second-title-experiences title-one'>
            <h1>Nossas</h1>
          </Col>
        </Row>
        <Row>
          <Col className='second-title-experiences title-two'>
            <h1>Experiências</h1>
          </Col>
        </Row>
        {videos.map( ( video, index ) => (
          <>
            {video.direction == "left" ?
              <>
                <Row key={index}>
                  <Col xs={12} sm={12} lg={{ span: 3 }} xl={{ span: 3 }} className="video-title video-space">
                    {video.tags.map( ( tag, index ) => (
                      <>
                        {
                          tag.award != undefined &&
                          <span className="label award-video">
                            {tag.award}
                          </span>
                        }
                      </>
                    ) )}
                    <h2 className="desk">{video.tags[0].name}</h2>
                    <h2>{video.title}</h2>
                    <p className="video-title-agency">{video.agency}</p>
                    <MDBContainer className="video-mdb mobile">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                    <p className="video-description">
                      {video.description}
                    </p>
                  </Col>
                  <Col lg={{ span: 8, offset: 1 }} xl={{ span: 8, offset: 1 }} className="desk video-space">
                    <MDBContainer className="video-mdb">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                  </Col>
                </Row>
              </>
              :
              <Row key={index}>
                <Col md={4} lg={8} xl={8} className="desk video-space">
                  <MDBContainer className="video-mdb">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                </Col>
                <Col xs={12} sm={12} md={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }} className="video-title video-space">
                  {video.tags.map( ( tag, index ) => (
                    <>
                      {
                        tag.award != undefined &&
                        <span className="label award-video">
                          {tag.award}
                        </span>
                      }
                    </>
                  ) )}
                  <h2 className="desk">{video.tags[0].name}</h2>
                  <h2>{video.title}</h2>
                  <p className="video-title-agency">{video.agency}</p>
                  <MDBContainer className="video-mdb mobile">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                  <p className="video-description">
                    {video.description}
                  </p>
                </Col>
              </Row>
            }
          </>
        ) )}
        <Row>
          <Col xs={{ span: 6, offset: 6 }} sm={{ span: 6, offset: 6 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} xl={{ span: 8, offset: 4 }} className="video-arrow">
            <a className='mobile' target='blank' href='https://vimeo.com/showcase/10560028'>
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={32} />
              </p>
            </a>
            <a className='desk' target='blank' href='https://vimeo.com/showcase/10560028'>
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={42} />
              </p>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Experience;