
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { ArrowRight } from "react-bootstrap-icons";
import { Footer } from "./Footer";
import Navbar from './Navbar';
import Elipse from './assets/elipse-blue.png'
import ElipseImage from './assets/elipse-image.png'
import "./styles/sobre.css"

const About = () => {

  return (
    <>
      <Navbar />
      <Container fluid className='section section-about'>
        <Row>
          <Col className='title-page'>
            <h4>Sobre nós</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={4} xl={4} className='subtitle-about'>
            <p>
              Somos uma produtora multidisciplinar que conecta as esferas digitais, criativas, de pesquisa, de produção e cocriação.
            </p>
          </Col>
          <Col lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }} className='subtitle-one subtitle-right'>
            <p>
              Como estrategistas narrativos ajudamos marcas a interpretar as linguagens que estão moldando o futuro da tecnologia e amplificando <span className='link-about'>o poder das boas histórias</span>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <span class="elipses-about elipse-one desk">
              <img src={Elipse} alt="elipse-3" />
            </span>
          </Col>
          <Col  lg={6} xl={6} className='subtitle-right desk'>
            <p>
              Unimos a arte e a tecnologia para criar filmes e experiências que <span className='link-about'>ultrapassam os formatos tradicionais</span>, onde a narrativa é o que nos conecta com o novo.
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} className='subtitle-right mobile'>
            <p>
              Unimos a arte e a tecnologia para criar filmes e experiências que <span className='link-about'>ultrapassam os formatos tradicionais</span>, onde a narrativa é o que nos conecta com o novo.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <span class="elipses-about desk">
              <img src={Elipse} alt="elipse-3" />
            </span>
          </Col>
          <Col className='subtitle-right terceiro-texto desk'>
            <p>
              Nos orgulhamos em ser uma empresa que não acredita em formatos e sim na <span className='link-about'>potencialidade das ideias</span>. Somos parceiros estratégicos das agências e marcas que, como a gente, acreditam na criatividade e no poder da narrativa.  {/* <span className='link-about'>Experiências Imersivas únicas</span> */}
            </p>
          </Col>
          <Col xs={12} sm={12} className='subtitle-right terceiro-texto mobile'>
            <p>
              Nos orgulhamos em ser uma empresa que não acredita em formatos e sim na <span className='link-about'>potencialidade das ideias</span>. Somos parceiros estratégicos das agências e marcas que, como a gente, acreditam na criatividade e no poder da narrativa.  {/* <span className='link-about'>Experiências Imersivas únicas</span> */}
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='second-section-about'>
        <Row className='subtitle-about'>
          <Col lg={6} xl={6}>
            <span class="elipses-about desk">
              <img src={ElipseImage} alt="elipse-3" />
            </span>
          </Col>
          <Col lg={5} className='subtitle-about'>
            <p >
              Uma cúpula gigante com projeção 360º; um salto de um Camaro de um caminhão para o outro em movimento; uma Shadow Art no Guinness Records ou um show no meio de um milharal.... Nada parecia possível, até a NSI realizar.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 8, offset: 4 }} sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }} className="video-arrow">
            <a className='mobile' target='blank' href='https://vimeo.com/showcase/10560028'>
              <p className="text-about-impossible text-medium-fraunces-3">
              Vamos fazer o
              impossível juntos?
                <ArrowRight className="arrow-right" size={32} />
              </p>
            </a>
            <a className='desk' target='blank' href='https://vimeo.com/showcase/10560028'>
              <p className="text-about-impossible text-medium-fraunces-3">
              Vamos fazer o
              <br/>
              impossível juntos?
                <ArrowRight className="arrow-right" size={42} />
              </p>
            </a>
          </Col>
        </Row>
      </Container >
      <Footer />
    </>
  )
}

export default About;

/**
 *  
      <Container className='second-section-about desk'>
        <Row>
          <Col />
          <Col>
            <Row className='subtitle-about'>
              <Col className='text-medium-instruments-1 description-about description-about-three'>
                <p>
                  Pensamos projetos através do Craft, Design e Inovação: nos filmes, nos projetos de conteúdo ou nas experiências.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='text-medium-instruments-1 description-about description-about-four'>
                <p>
                  O resultado de tudo isso, além da excelência na entrega dos projetos, são prêmios e indicações que vão de Cannes ao Grammy Latino e nosso nome no Guinness Book.
                </p>
              </Col>
            </Row></Col>
        </Row>
        <Row>
          <Col lg={6}/>
          <Col>
            <h1 className="text-about-impossible text-medium-fraunces-3">
              Vamos fazer o
              impossível juntos?
              <ArrowRight size={30} />
            </h1>
          </Col>
        </Row>
      </Container>
      <Container fluid className='section section-about mobile'>
        <Row>
          <Col className='title-about'>
            <h1>Sobre nós</h1>
          </Col>
        </Row>
        <Row className='subtitle-about'>
          <Col>
            <p>
              Somos uma produtora especializada no Impossível. Para nós, o impossível é um conceito poderoso, uma forma de pensar e produzir.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Nos guiamos pelos desafios – e assim idealizamos soluções tecnológicas, criativas e operacionais a favor da narrativa.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Nascemos para realizar <span className='first-description-bigger-link'>filmes publicitários</span> que carregam grandes desafios de produção.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Estruturamos e produzimos  <span className='second-description-bigger-link'>projetos de inovação e tecnologia</span> com uma forte narrativa.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Criamos e <br /> produzimos  <span className='third-description-bigger-link'>Experiências Imersivas únicas</span>, que misturam design e audiovisual.
            </p>
          </Col>
        </Row>
      </Container >
      <Container fluid className='section-about mobile'>
        <Row>
          <Col className='title-about'>
            <h1 className='text-medium-fraunces'>Sobre nós</h1>
          </Col>
        </Row>
        <Row className='subtitle-about'>
          <Col className='text-medium-instruments-1'>
            <p>
              Somos uma produtora especializada no Impossível. Para nós, o impossível é um conceito poderoso, uma forma de pensar e produzir.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='text-medium-instruments-1'>
            <p>
              Nos guiamos pelos desafios – e assim idealizamos soluções tecnológicas, criativas e operacionais a favor da narrativa.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Nascemos para realizar <span className='first-description-bigger-link'>filmes publicitários</span> que carregam grandes desafios de produção.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Estruturamos e produzimos  <span className='second-description-bigger-link'>projetos de inovação e tecnologia</span> com uma forte narrativa.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='title-h1-fraunces-1 text-about-topics'>
              Criamos e <br /> produzimos  <span className='third-description-bigger-link'>Experiências Imersivas únicas</span>, que misturam design e audiovisual.
            </p>
          </Col>
        </Row>
      </Container >
      <Container className='second-section-about mobile'>
        <Row className='subtitle-about'>
          <Col className='text-medium-instruments-1'>
            <p>
              Somos uma produtora especializada no Impossível. Para nós, o impossível é um conceito poderoso, uma forma de pensar e produzir.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className='text-medium-instruments-1'>
            <p>
              Nos guiamos pelos desafios – e assim idealizamos soluções tecnológicas, criativas e operacionais a favor da narrativa.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={1} />
          <Col>
            <h1 className="text-about-impossible text-medium-fraunces-3">
              Vamos fazer o
              impossível juntos?
              <ArrowRight size={30} />
            </h1>
          </Col>
        </Row>
      </Container>
 */